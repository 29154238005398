.landing-page {
    position: relative;
    background-color: #F0F0F0;
    overflow: hidden;
}

.sliderimg {
    width: 100%;
    height: 680px;
    object-fit: cover; /* Ensures the image covers the area without distortion */
}

.carousel-item {
    position: relative;
}

.overlay-text {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: right; /* Align text to the right */
    color: #FFFFFF; /* Light text color for contrast */
    padding: 20px;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background for contrast */
    border-radius: 8px;
    width: auto; /* Adjust width to fit content */
}

.overlay-text h1 {
    font-size: 2.5rem; /* Slightly smaller font size */
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'Arial', sans-serif; /* Clean and modern font */
}

.overlay-text p {
    font-size: 1.2rem;
    font-family: 'Arial', sans-serif;
}
