html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.app {
  font-family: Arial, sans-serif;
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black with transparency */
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0px;
  height: 90px;
}

.logo-section img {
  width: 80px;
  height: auto;
  padding: 5px;
  margin-left: 30px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  color: white;
}

.menu {
  display: flex;
  align-items: center;
}

.header-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  font-weight: 500;
}

.header-button:hover {
  color: #FFA500;
}

.carousel-item img {
  width: 100%;
  height: 720px;
  object-fit: cover;
}

.carousel-item {
  position: relative;
  width: 100%;
  height: 720px;
}

.slider-text {
  position: absolute;
  bottom: 90px;
  left: 85%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 10px;
  width: 40%;
  animation: textFade 3s;
}

.sliderimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-text h2 {
  font-size: 40px;
}

.slider-text p{
  font-size: 20px;
}
.section {
  padding-top: 100px; /* Padding for space */
}

@keyframes textFade {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sliderimg {
    height: 400px; /* Smaller height for mobile screens */
  }
  .carousel-item img {
    width: 100%;
    height: 386px;
    object-fit: contain;
  }
  .carousel-item {
    position: relative;
    width: 100%;
    height: 293px;
  }

  .alice-style {
    height: 294px;
  }

  .menu {
    display: none; /* Hide the menu initially on smaller screens */
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px 0;
  }

  .menu-open {
    display: flex; /* Show the menu when the state is toggled */
  }

  .menu-icon {
    display: block; /* Show the hamburger icon on smaller screens */
    margin-right: 30px;
  }

  .header-button {
    padding: 15px 20px;
    text-align: center;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .header-button:first-child {
    border-top: none;
  }
  .slider-text {
    position: absolute;
    bottom: 90px;
    left: 85%;
    transform: translateX(-50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 10px;
    width: 40%;
    animation: textFade 3s;
  }
  .slider-text {
    bottom: 50px; /* Move the text a little higher on tablets */
    width: 50%;    /* Increase width to accommodate more text */
    left: 80%;     /* Adjust the left position */
  }

  .slider-text h2 {
    font-size: 32px; /* Decrease font size for tablets */
  }

  .slider-text p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .sliderimg {
    height: 400px; /* Smaller height for tablets */
  }

  .carousel-item img {
    height: 386px;
    object-fit: contain;
  }

  .carousel-item {
    position: relative;
    width: 100%;
    height: 293px;
  }

  .alice-style {
    height: 294px;
  }

  .slider-text {
    width: 50%;    /* Increase width */
    left: 80%;     /* Adjust left position */
    bottom: 0px;
    padding: 0px;
    padding-left: 15px;
  }

  .slider-text h2 {
    font-size: 32px; /* Decrease font size */
  }

  .slider-text p {
    font-size: 18px;
  }
}

/* For Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .slider-text {
    width: 90%;     /* Full-width on small screens */
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    padding: 0px;
    padding-left: 15px;
  }

  .carousel-item img {
    height: 386px; /* Smaller height for mobile */
    object-fit: contain;
  }

  .alice-style {
    height: 292px;
  }

  .slider-text h2 {
    font-size: 20px; /* Smaller font size */
  }

  .slider-text p {
    font-size: 14px;
  }
}