/* Main container for the amenities section */
.Amenities {
    background-color: #F0F0F0;
    padding: 20px 10px;
    text-align: center;
}

.AmenitiesStyle {
    background-color: white;
    color: #C45508;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.AmenitiesStyle::before {
    content: ''; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #C45508;
    border-radius: 8px 8px 0 0;
}

.section-title {
    font-size: 36px;
    color: #C45508;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.container4 {
    display: flex;
    justify-content: space-between;
}

.column2 {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 10px;
}

.amenity-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.amenity-card:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.check-icon {
    font-size: 20px;
    color: #C45508;
    margin-right: 15px;
    transition: transform 0.3s ease;
}

.amenity-card:hover .check-icon {
    transform: rotate(360deg);
}

.amenity-text {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    font-family: 'Poppins', sans-serif;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.fade-in {
    animation: fadeIn 2s ease-out forwards;
}

@media (max-width: 768px) {
    .container4 {
        flex-direction: column;
        align-items: center;
    }
    .column2 {
        width: 100%;
    }
}
