.SiteOuterStyle {
    background-color: white;
    color: #C45508 ;
    margin-top: 0px;
    padding: 10px;
    transform: perspective(800px);
    transition: transform 1s ease 0s;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
    rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.SiteOuter {
    background-color: #F0F0F0;
    padding: 10px;
}

.row {
    display: flex;
}

.column {
    
    margin: 10px;
    padding: 20px;
  } 

.container1 {
    text-align: center;
}

.polaroid {
    background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  &:hover {
    transform: scale(1.1);
  }
}

.zoom {
    width: 100%;
}
@media (max-width: 768px) {
  .SiteOuter {
    background-color: #F0F0F0;
    padding: 10px;
}
.polaroid {
  background-color: white;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
&:hover {
  transform: scale(1.1);
}
}
.column {
    
  margin: 10px;
  padding: 0px;
  border-radius: 7px;
} 
}
