/* Floating contact icon */
.contact-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #C45508;
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  
  .contact-icon:hover {
    transform: scale(1.1);
  }
  
  /* Contact panel that slides in */
  .contact-panel {
    position: fixed;
    bottom: 90px;
    right: -100%; /* Initially hidden */
    width: 300px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 999;
  }
  
  /* Open state of the contact panel */
  .contact-panel.open {
    right: 20px; /* Slide it into view */
  }
  
  /* Close button for the panel */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: black;
  }

@media (max-width: 768px) {
    .contact-panel {
        position: fixed;
        bottom: 90px;
        right: -100%; /* Initially hidden */
        width: 200px;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding: 20px;
        transition: right 0.3s ease;
        z-index: 999;
    }
}
  