/* Loader.css */
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    transition: opacity 0.5s ease-in-out;  }
  
  .loader img {
    width: 200px; /* Adjust the width of the logo */
    height: auto; /* Maintain aspect ratio */
    /*animation: spin 2s linear infinite;*/
    animation: scale 2s linear infinite alternate; /* Apply scaling animation */ 
} 
/*@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }*/

  @keyframes scale {
    0% {
      transform: scale(1); /* Initial scale */
    }
    50% {
      transform: scale(1.2); /* Enhance scale */
    }
    100% {
      transform: scale(0.8); /* Reduce scale */
    }
  }
  



  
  