/* Main container for footer */
.footer-container {
    position: relative;
    background-image: url("../images/office\ img6.webp"); /* Background image */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
}

/* Overlay for darkening background image */
.footer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

/* Footer content inside */
.footerContent {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    color: white;
}

/* Footer columns */
.footer-column {
    flex: 1;
    margin: 10px;
    padding: 10px;
}

/* Footer logo */
.footer-logo {
    width: 200px;
    height: auto;
    margin-left: 35px; /* Maintains aspect ratio */
}

.about-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 0; /* Remove padding on sides for consistent spacing */
    cursor: pointer;
    transition: color 0.3s ease;
    font-weight: 500;
    text-align: left;
    width: 100%;
}

.about-button:hover {
    color: #d18616; /* Optional hover color */
}


/* Contact and About Us headings */
.footer-column h3 {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 700;
    border-bottom: 2px solid #d18616; /* Customize color and thickness */
    display: inline-block; /* This keeps the underline the same width as the text */
    padding-bottom: 5px;
}

/* Text styles for contact info */
.footer-column p {
    font-size: 15px;
    display: flex;
    align-items: center;
    font-weight: 400;
}

.footer-column p svg {
    margin-right: 10px;
    font-size: 18px;
}

/* Social Media Icons */
.social-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.social-icons a {
    font-size: 18px;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #d18616; /* Optional hover color */
}

/* Footer bottom section */
.footer-bottom {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
    position: relative;
    z-index: 2;
    color: #ddd;
    margin-top: 20px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer-logo {
        width: 146px;
        height: auto;
        margin-left: 50px;
        margin-top: -15%;
    }
  }
  
  @media (max-width: 480px) {
    .footer-logo {
        width: 146px;
        height: auto;
        margin-left: 50px;
        margin-top: -15%;
    }
  }  