.overview {
    background-color: #F0F0F0;
    padding: 40px 0;  /* You may adjust or remove this padding depending on your design needs */
    display: flex;  /* Enables flexbox layout */
    justify-content: center;  /* Centers horizontally in the flex container */
    align-items: center;  /* Centers vertically in the flex container */
    width: 100%;  /* Takes full width of the viewport or parent element */
}

.overviewStyle {
    background-color: white;
    color: #333;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
    width: 100%;  /* Adapts to the width of the parent container */
    max-width: 1490px;  /* Ensures it doesn't grow beyond 1300px */
    position: relative;  /* Keeps the pseudo-elements positioned relative to this container */
    overflow: hidden;  /* Ensures no overflow from pseudo-elements */
    box-sizing: border-box;  /* Includes padding and border in the element's total width and height */
}

.overview-title, .overview-text, .overview-cta {
    text-align: center;  /* Centers text horizontally */
}

/* Maintaining other styles as they are, adjusting only what's necessary for centering */


.overviewStyle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #C45508;
}

.overview-title {
    text-align: center;
    font-size: 2.2rem;
    color: #C45508;
    margin-bottom: 20px;
}

.overview-text {
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: justify;
    color: #666;
}

.overview-cta {
    background-color: #C45508;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.overview-cta:hover {
    background-color: #b04a07;
}

.overviewStyle:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0,0,0,0.15);
}

@media (max-width: 768px) {
    .overviewStyle {
        background-color: white;
        color: #333;
        padding: 40px;
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0,0,0,0.1);
        width: 95%;  /* Adapts to the width of the parent container */
        max-width: 1450px;  /* Ensures it doesn't grow beyond 1300px */
        position: relative;  /* Keeps the pseudo-elements positioned relative to this container */
        overflow: hidden;  /* Ensures no overflow from pseudo-elements */
        box-sizing: border-box;  /* Includes padding and border in the element's total width and height */
    }
}
