.row2 {
    display: flex;
    justify-content: center; /* Center the single image */
}

.column1 {
    margin: 10px;
    flex: 1 1 30%; /* Flexbox: grow, shrink, and set width to 30% */
    max-width: 500px; /* Max width for each image */
    height: 350px; /* Fixed height */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden; /* Ensures image doesn’t overflow the container */
    transition: transform 0.3s ease;
}

.column1:hover {
    transform: scale(1.05);
}

.zoom {
    width: 100%; 
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.GalleryStyle {
    background-color: white;
    color: #C45508;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Gallery {
    background-color: #F0F0F0;
    padding: 10px;
}

.gallery-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.arrow-icon {
    font-size: 40px;
    cursor: pointer;
    margin: 0 20px;
    color: #034694;
    transition: color 0.3s ease;
}

.arrow-icon:hover {
    color: #d18616;
}

.arrow-icon.disabled {
    color: #ccc;
    cursor: not-allowed;
}

.arrow-icon.disabled:hover {
    color: #ccc;
}

/* Media Query for mobile screens to show only one image */
@media (max-width: 768px) {
    .row2 {
        justify-content: center;
    }
    .column1 {
        max-width: 100%;
        height: auto; /* Make the height adjust based on the image */
    }
}
