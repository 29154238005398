/* Main container for both location and enquiry form */
.location-enquiry-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Ensure both items stretch to the same height */
    margin-top: 20px;
    padding: 20px;
}

/* Styling for the location container */
.Location {
    flex: 1;
    background-color: #F0F0F0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.location-title {
    color: #c45508;
}

/* Styling for the enquiry form container */
.enquiry-form {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.enquiry-form h2{
    color:#c45508;
    font-size: 30px;
}

/* Input field styles */
.enquiry-form input, .enquiry-form textarea {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Styling for the submit button */
.enquiry-form button {
    background-color: #c45508;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.enquiry-form button:hover {
    background-color: #c45508;
}

/* Map styling */
.MapStyle {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive behavior */
@media (max-width: 768px) {
    .location-enquiry-container {
        flex-direction: column;
        align-items: center;
    }

    .Location, .enquiry-form {
        width: 100%;
        margin-bottom: 20px;
    }
}
